import { useTranslation } from "react-i18next";

import Typo from "~/components/atoms/Typo";
import MasonryGalleryLDP from "~/components/molecules/MasonryGalleryLDP";
import SectionProject from "~/components/templates/SectionProject";
import { IProject } from "~/entities/project";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface SectionProjectMasonryGalleryProps {
  className?: string;
  id: string;
  title: string;
  project: IProject;
}

const SectionProjectMasonryGalleryLDP: React.FC<
  SectionProjectMasonryGalleryProps
> = ({ className = "", id, title, project }) => {
  const { t } = useTranslation();

  if (!project) return <></>;
  return (
    <SectionProject
      id={id}
      className={cn(
        "relative flex flex-col gap-y-12 overflow-hidden py-20 lg:px-4 lg:py-12",
        className
      )}
    >
      <Typo
        tag={ETypoTag.H3}
        variant={ETypoVariant.HEADER_PROJECT_24}
        color={ETypoColor.PROJECT_HEADING}
        className="font-bold"
      >
        {t(title)}
      </Typo>
      <MasonryGalleryLDP project={project} />
    </SectionProject>
  );
};

export default SectionProjectMasonryGalleryLDP;
