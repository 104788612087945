import React from "react";
import { useTranslation } from "react-i18next";

import { ProjectLocationMapLDP } from "~/components/organisms/ProjectLocationMapLDP";
import SectionProject from "~/components/templates/SectionProject";
import { IProject } from "~/entities/project";

export interface SectionProjectLocationMapProps {
  className?: string;
  id: string;
  title: string;
  description: string;
  project: IProject;
}

const SectionProjectLocationMapLDP: React.FC<
  SectionProjectLocationMapProps
> = ({ className = "", id, title, description, project }) => {
  const { t } = useTranslation();

  if (!project) return null;

  return (
    <SectionProject id={id} className={className}>
      <ProjectLocationMapLDP
        title={t(title)}
        desc={t(description)}
        projectLocations={project?.amenityHtml || ""}
        mapEmbedUrl={project?.googleMapUrl || ""}
        containerClassName=""
      />
    </SectionProject>
  );
};

export default SectionProjectLocationMapLDP;
