import { ReactNode, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { SwiperOptions } from "swiper/types";

import {
  MAX_WIDTH_CONTAINER,
  PADDING_MEDIUM_SCREEN,
  PADDING_SMALL_SCREEN,
} from "./utils";

import { Swiper } from "~/components/atoms/Swiper";
import { ForwardedSwiper } from "~/components/atoms/Swiper/Swiper";
import { SwiperSlide } from "~/components/atoms/Swiper/SwiperSlide";
import Typo from "~/components/atoms/Typo";
import VirtualCard from "~/components/molecules/VirtualCard";
import VirtualView from "~/components/molecules/VirtualView";
import { IProject } from "~/entities/project";
import { IVirtualTour } from "~/entities/virtualTour";
import useMediaQuery from "~/hooks/use-mediaquery";
import { useModal } from "~/hooks/use-modal";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { Slug } from "~/utilities/enums/Slug";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

export interface VirtualToursLDPProps {
  title: string;
  description?: string;
  select?: ReactNode;
  paddingClass?: string;
  className?: string;
  thumbnailClass?: string;
  virtualTours: IVirtualTour[];
  project: IProject;
}

const ProjectVirtualTourLDP: React.FC<VirtualToursLDPProps> = ({
  title,
  description,
  select,
  virtualTours,
  project,
  className = "",
  thumbnailClass = "",
}) => {
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [offset, setOffset] = useState<number>(0);
  const [swiperKey, setSwiperKey] = useState<number>(0);
  const swiperRef = useRef<ForwardedSwiper | null>(null);
  const isLargeScreen = useMediaQuery("lg");

  const [iframe, setIframe] = useState<string | undefined>(undefined);

  const { modal, openModal, closeModal } = useModal({
    children: <VirtualView iframe={iframe} onClick={() => closeModal()} />,
    allowBackdropClick: true,
    className:
      "w-full h-full max-w-full max-h-full border-none rounded-none bg-black p-0",
  });

  useEffect(() => {
    const updateOffset = () => {
      const padding =
        window.innerWidth > MAX_WIDTH_CONTAINER
          ? PADDING_MEDIUM_SCREEN
          : PADDING_SMALL_SCREEN;

      const calculatedOffset =
        window.innerWidth > MAX_WIDTH_CONTAINER
          ? (window.innerWidth - MAX_WIDTH_CONTAINER) / 2
          : padding;

      console.log("calculatedOffset", calculatedOffset);

      setOffset(calculatedOffset);
    };

    updateOffset();
    window.addEventListener("resize", updateOffset);

    return () => {
      window.removeEventListener("resize", updateOffset);
    };
  }, []);

  const options: SwiperOptions = {
    slidesPerView: "auto",
    slidesOffsetBefore: offset,
    slidesOffsetAfter: offset,
    initialSlide: 0,
    spaceBetween: 0,
    setWrapperSize: true,
    centeredSlides: true,
    rewind: true,
    breakpointsBase: "window",
    width: 400,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    on: {
      slideChange: (swiper) => {
        const { activeIndex } = swiper;
        setActiveIndex(activeIndex);
      },
    },
  };

  useEffect(() => {
    setSwiperKey((prevKey) => prevKey + 1);
  }, [offset]);

  return (
    <div className={cn("flex flex-col gap-8 lg:hidden lg:gap-5", className)}>
      <div className="ldp flex flex-row items-center justify-between">
        <div className=" flex w-full items-end justify-between gap-x-6 lg:mb-3.75 lg:flex-col lg:items-start lg:gap-y-5">
          <div className="flex flex-col gap-y-2 lg:w-full">
            <Typo
              tag={ETypoTag.H2}
              variant={ETypoVariant.HEADER_PROJECT_24}
              color={ETypoColor.PROJECT_HEADING}
              className="font-bold"
            >
              {title}
            </Typo>
            <Typo tag={ETypoTag.SPAN} variant={ETypoVariant.SUB_TITLE_16}>
              {description}
            </Typo>
          </div>
          <div className="-mb-1 w-1/2 lg:mb-0 lg:w-full">{select}</div>
        </div>

        {/* {!!virtualTours?.length && (
          <div className="space-x-3">
            <Button
              size={isLargeScreen ? "wideMobile" : "wide"}
              variant={activeIndex === 0 ? "disabled" : "default"}
              onClick={() => {
                swiperRef?.current?.core?.swiper?.slidePrev();
              }}
            >
              <Icon TypeIcon={EIcon.ArrowLeftIcon} height={40} width={20} />
            </Button>
            <Button
              size={isLargeScreen ? "wideMobile" : "wide"}
              variant={
                activeIndex === virtualTours.length - 1 ? "disabled" : "default"
              }
              onClick={() => {
                swiperRef?.current?.core?.swiper?.slideNext();
              }}
            >
              <Icon TypeIcon={EIcon.ArrowRightIcon} height={40} width={20} />
            </Button>
          </div>
        )} */}
      </div>
      <div className="relative w-screen">
        {offset > 0 && (
          <Swiper
            key={swiperKey}
            className="overflow-initial"
            defaultValue={activeIndex}
            ref={swiperRef}
            {...{
              ...options,
              slidesOffsetBefore: offset,
              slidesOffsetAfter: offset,
            }}
          >
            {virtualTours?.map((virtualTour, index: number) => (
              <SwiperSlide
                key={virtualTour.id + index}
                className=" w-[400px] cursor-pointer px-2 first:pl-0 last:pr-0"
              >
                {/* <CardBanner
                  promotion={virtualTour}
                  className="h-[360px] rounded-3xl lg:h-[146px] lg:rounded-lg"
                  imageClass="lg:h-[146px] h-[360px] rounded-3xl lg:rounded-lg"
                /> */}
                <VirtualCard
                  key={virtualTour.id}
                  onClick={() => {
                    openModal();
                    setIframe(virtualTour.url);
                  }}
                  className="group cursor-pointer md:pb-5"
                  thumbnailClass={cn(
                    "lg:h-[182px] h-[224px] rounded-none",
                    thumbnailClass
                  )}
                  data={{
                    types: virtualTour.unitType?.title || "",
                    url: `${Slug.PROJECTS}/${project?.slug || ""}`,
                    urls: virtualTour.thumbnail,
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      {modal}
    </div>
  );
};

export default ProjectVirtualTourLDP;
