import React, { useEffect, useState } from "react";

import { IProjectLocationMapProps } from "./types";

import CleanedContent from "~/components/atoms/CleanedContent";
import Typo from "~/components/atoms/Typo";
import { cn } from "~/utilities/cn";
import { ETypoColor } from "~/utilities/enums/Colors";
import { ETypoTag, ETypoVariant } from "~/utilities/enums/Typo";

const ProjectLocationMapLDP: React.FC<IProjectLocationMapProps> = ({
  title,
  desc,
  mapEmbedUrl,
  projectLocations,
  containerClassName,
}) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <div
      className={cn("flex gap-6 px-0 lg:flex-col lg:px-0", containerClassName)}
    >
      <div className="order-2 flex basis-1/3 flex-col gap-2 pl-12 lg:order-1 lg:basis-1 lg:pl-0">
        <Typo
          tag={ETypoTag.H3}
          variant={ETypoVariant.HEADER_PROJECT_24}
          color={ETypoColor.PROJECT_HEADING}
          className="font-bold"
        >
          {title}
        </Typo>
        {desc && (
          <Typo variant={ETypoVariant.BODY_TITLE_16} tag={ETypoTag.P}>
            {desc}
          </Typo>
        )}
        {projectLocations && (
          <CleanedContent
            htmlContent={projectLocations || ""}
            className="scroll h-fit max-h-[362px] w-full overflow-auto text-body-title-16 text-color lg:hidden lg:max-h-[320px]"
          />
        )}
      </div>
      {isClient && mapEmbedUrl && (
        <div className="order-1 h-[420px] w-full basis-2/3 lg:order-2 lg:basis-full">
          <iframe
            title={title}
            src={mapEmbedUrl}
            height="420"
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="w-full"
          />
        </div>
      )}
      {projectLocations && (
        <CleanedContent
          htmlContent={projectLocations || ""}
          className="scroll hidden h-fit max-h-[362px] w-full overflow-auto text-body-title-16 text-color lg:order-3 lg:block lg:max-h-[320px]"
        />
      )}
    </div>
  );
};

export default ProjectLocationMapLDP;
