import { forwardRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { useRemixForm } from "remix-hook-form";

import { SingleSelect } from "~/components/atoms/SelectBox";
import { SelectOptions } from "~/components/atoms/SelectBox/SingleSelect";
import ProjectVirtualTourLDP from "~/components/organisms/ProjectVirtualTourLDP";
import ProjectVirtualTourLDPMobile from "~/components/organisms/ProjectVirtualTourLDPMobile";
import { Form } from "~/components/templates/form";
import Section from "~/components/templates/SectionProject";
import { IProject } from "~/entities/project";
import { IVirtualTour } from "~/entities/virtualTour";
import { cn } from "~/utilities/cn";
import { OPTION_ANY } from "~/utilities/constants/common";
import {
  filterVirtualTourSchema,
  FilterVirualTourFormData,
} from "~/utilities/schema/filter-virtual-tour";

export interface SectionProjectVirtualTourProps {
  className?: string;
  id: string;
  title: string;
  description: string;
  virtualTours: IVirtualTour[];
  unitTypeOptions: SelectOptions[];
  project: IProject;
}

export type Ref = HTMLDivElement;

const SectionProjectVirtualTourLDP = forwardRef<
  Ref,
  SectionProjectVirtualTourProps
>(
  (
    {
      className = "",
      id,
      title,
      description,
      virtualTours,
      unitTypeOptions,
      project,
    },
    ref
  ) => {
    const { t } = useTranslation();
    const handleFormInit = async (
      _methods: ReturnType<typeof useRemixForm<FilterVirualTourFormData>>
    ) => {};
    const [localVirtualTours, setLocalVirtualTours] = useState<IVirtualTour[]>(
      []
    );
    const isMobile = useMediaQuery({ query: "(max-width: 992px)" });

    const Component = isMobile
      ? ProjectVirtualTourLDPMobile
      : ProjectVirtualTourLDP;

    useEffect(() => {
      setLocalVirtualTours([...(virtualTours || [])]);
    }, [virtualTours]);
    return (
      <Section
        ref={ref}
        id={id}
        className={cn("py-20 lg:w-full lg:py-12", className)}
      >
        <Component
          paddingClass="lg:px-4"
          virtualTours={localVirtualTours}
          title={t(title) || ""}
          description={
            t(description, { total: localVirtualTours?.length ?? 0 }) || ""
          }
          thumbnailClass="lg:rounded-none bg-backgroundImage"
          project={project}
          select={
            <Form<FilterVirualTourFormData>
              validationSchema={filterVirtualTourSchema}
              onMethodInit={handleFormInit}
              useFormProps={{
                mode: "onChange",
              }}
            >
              {({ register, formState: { errors } }) => (
                <SingleSelect
                  placeholder="Any"
                  error={errors.type?.message}
                  data={unitTypeOptions}
                  name="type"
                  defaultValue={OPTION_ANY}
                  dropdownClass="w-[var(--button-width)]"
                  onChange={(value: string) => {
                    setLocalVirtualTours(
                      virtualTours?.filter(
                        (virtualTour) =>
                          virtualTour?.unitTypeId === value ||
                          value === OPTION_ANY?.id
                      )
                    );
                  }}
                />
              )}
            </Form>
          }
        />
      </Section>
    );
  }
);

SectionProjectVirtualTourLDP.displayName = "SectionProjectVirtualTourLDP";

export default SectionProjectVirtualTourLDP;
